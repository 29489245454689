import Logo from 'design/assets/images/payforce_logo.png'
import Hamburger from 'design/icons/Hamburger'
import AgentsActive from 'design/icons/agents-active.svg'
import AgentsBase from 'design/icons/agents-base.svg'
import ApiIconActive from 'design/icons/api-active.svg'
import ApiIcon from 'design/icons/api.svg'
import BillersActive from 'design/icons/billers-active.svg'
import BillersBase from 'design/icons/billers-base.svg'
import CampaignActive from 'design/icons/campaign-active.svg'
import CampaignBase from 'design/icons/campaign-base.svg'
import ChargesActive from 'design/icons/charge-active.svg'
import ChargesBase from 'design/icons/charge-base.svg'
import DashboardActive from 'design/icons/dashboard-active.svg'
import DashboardBase from 'design/icons/dashboard-base.svg'
import DispenseErrorsActive from 'design/icons/dispense-error-active.svg'
import DispenseErrorsBase from 'design/icons/dispense-error-base.svg'
import FundsActive from 'design/icons/funds-active.svg'
import FundsBase from 'design/icons/funds-base.svg'
import InfluencerActive from 'design/icons/influencer-active.svg'
import InfluencerBase from 'design/icons/influencer-base.svg'
import LienActive from 'design/icons/lien-active.svg'
import LienBase from 'design/icons/lien-base.svg'
import LoanDark from 'design/icons/loan-active.svg'
import LoanLight from 'design/icons/loan-base.svg'
import WalletLoanV2Dark from 'design/icons/wallet-loan1.svg'
import WalletLoanV2Light from 'design/icons/wallet-loan2.svg'
import WalletLoanV3Light from 'design/icons/wallet-loans3.svg'
import MiscellActive from 'design/icons/miscelleanous-active.svg'
import MiscellBase from 'design/icons/miscelleanous-base.svg'
import NotificationActive from 'design/icons/notification-active.svg'
import NotificationBase from 'design/icons/notification-base.svg'
import qr_code_dark from 'design/icons/qr_code_dark.png'
import qr_code_light from 'design/icons/qr_code_light.png'
import ReportsActive from 'design/icons/reports-active.svg'
import ReportsBase from 'design/icons/reports-base.svg'
import ReviewActive from 'design/icons/review-active.svg'
import ReviewBase from 'design/icons/review-base.svg'
import SettingsActive from 'design/icons/settings-active.svg'
import SettingsBase from 'design/icons/settings-base.svg'
// import ShopActive from 'design/icons/shop-active.svg'
// import ShopBase from 'design/icons/shop-base.svg'
import TerminalsBase from 'design/icons/terminal-base.svg'
import TerminalsActive from 'design/icons/terminals-active.svg'
import TMSActive from 'design/icons/tms-active.svg'
import TMSBase from 'design/icons/tms-base.svg'
import TransactionsActive from 'design/icons/transactions-active.svg'
import TransactionsBase from 'design/icons/transactions-base.svg'
import UsersActive from 'design/icons/users-active.svg'
import UsersBase from 'design/icons/users-base.svg'
import SalesReportBase from 'design/icons/sales-report-base.svg'
import SalesReportActive from 'design/icons/sales-report-active.svg'
import VirtualAccountActive from 'design/icons/virtual-Accounts-active.svg'
import KycBase from 'design/icons/Kyc-base.svg'
import KycActive from 'design/icons/kyc-active.svg'
import { capitalize } from 'lib/formatters'
import styled from 'styled-components'

import Box from 'design/elements/Box'
import Spacer from 'design/elements/Spacer'
import { ShowTree } from 'lib/gate/GateComponents'
import { AllRoles, HIGH_ROLES, NOTIFICATION_VIEW, except, roles } from 'lib/models'
import { NavLink } from 'react-router-dom'
import { useAuthState } from 'auth/context/Auth.Context'

const SideBarStyles = styled.aside`
  color: #bfe1ff;
  background-color: ${(props) => props.theme.colors.highlight};
  transition: width 0.256s;
  display: flex;
  flex-direction: column;

  .menu {
    flex: 1 0 80%;
  }

  .ham-wrapper {
    padding: 0em 1em;
    overflow: hidden;
    border-bottom: 1px solid #7e99b173;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: all 0.256s linear;
  }
  .bar {
    width: 1px;
    height: 30px;
    display: inline-block;
    margin: 0 1.2em;
    opacity: 0.2;
    background: currentColor;
  }

  .logo {
    width: 0;
    height: 15px;
    overflow: hidden;
    height: inherit;
    float: left;
    transition: 0.256s width;
  }
  &.isOpen {
    .logo {
      width: 100%;
    }
  }
`

const SideBar = ({ toggleSideBar, isOpen }) => {
  const { user } = useAuthState()
  return (
    <SideBarStyles className={isOpen ? 'isOpen' : ''}>
      <div className="ham-wrapper" onClick={toggleSideBar}>
        <img alt="logo" className="logo" src={Logo} />
        {isOpen && <span className="bar" />}
        <Hamburger />
      </div>

      <Box className="menu">
        <SidebarMenuItem
          title="Dashboard"
          isOpen={isOpen}
          baseIcon={DashboardBase}
          activeIcon={DashboardActive}
          text="Dashboard"
          url="/dashboard"
          roles={except([roles.KYC_TEAM])}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="agents"
          isOpen={isOpen}
          baseIcon={AgentsBase}
          activeIcon={AgentsActive}
          text="Agents"
          url="/agents"
          roles={AllRoles}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="apiUsersManagement"
          isOpen={isOpen}
          baseIcon={ApiIcon}
          activeIcon={ApiIconActive}
          text="API Users Mgt"
          url="/api-user-management"
          roles={except([roles.KYC_TEAM])}
        />
        {/* <Spacer mt="xl" />
        <SidebarMenuItem
          title="Payforce Shop"
          isOpen={isOpen}
          baseIcon={ShopBase}
          activeIcon={ShopActive}
          text="Payforce Shop"
          url="/payforce-shop"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.ACCOUNTS_TEAM,
            roles.POS_TEAM,
            roles.SUPPORT,
            roles.AGGREGATOR_MANAGER,
            roles.GROWTH_MANAGER,
            roles.OPPERATION_HEAD,
            roles.REGIONAL_RETENTION_MANAGER,
          ]}
        /> */}
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Miscellaneous"
          isOpen={isOpen}
          baseIcon={MiscellBase}
          activeIcon={MiscellActive}
          text="Miscellaneous"
          url="/miscellaneous"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.POS_TEAM,
            roles.SUPPORT,
            roles.AGGREGATOR_MANAGER,
            roles.GROWTH_MANAGER,
            roles.OPPERATION_HEAD,
            roles.REGIONAL_RETENTION_MANAGER,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="survey"
          isOpen={isOpen}
          baseIcon={ReviewBase}
          activeIcon={ReviewActive}
          text="Review"
          url="/review"
          roles={[roles.SUPER_ADMIN, roles.ADMIN, roles.SUPPORT]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Loans"
          isOpen={isOpen}
          baseIcon={LoanLight}
          activeIcon={LoanDark}
          text="Loans"
          url="/loans"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPPERATION_HEAD,
            roles.SUPPORT,
            roles.REGIONAL_RETENTION_MANAGER,
            roles.OPERATIONS_ASSISTANT,
          ]}
        />
        <SidebarMenuItem
          title="Loans V2"
          isOpen={isOpen}
          baseIcon={WalletLoanV2Light}
          activeIcon={WalletLoanV2Dark}
          text="Loans V2"
          url="/loans-v2"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPPERATION_HEAD,
            roles.SUPPORT,
            roles.REGIONAL_RETENTION_MANAGER,
            roles.OPERATIONS_ASSISTANT,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Loans V3"
          isOpen={isOpen}
          baseIcon={WalletLoanV3Light}
          activeIcon={WalletLoanV2Dark}
          text="Loans V3"
          url="/loans-v3"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPPERATION_HEAD,
            roles.SUPPORT,
            roles.REGIONAL_RETENTION_MANAGER,
            roles.OPERATIONS_ASSISTANT,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Marketing"
          isOpen={isOpen}
          baseIcon={InfluencerBase}
          activeIcon={InfluencerActive}
          text="Marketing"
          url="/marketing"
          roles={[roles.SUPER_ADMIN, roles.ADMIN, roles.OPERATIONS_MANAGER, roles.COMMS]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="lien"
          isOpen={isOpen}
          baseIcon={LienBase}
          activeIcon={LienActive}
          text="Liens"
          url="/lien"
          roles={[...HIGH_ROLES, roles.SUPPORT]}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="lienv1"
          isOpen={isOpen}
          baseIcon={LienBase}
          activeIcon={LienActive}
          text="Lien V1"
          url="/liens-v1"
          roles={AllRoles}
        />

        <Spacer mt="xl" />
        <SidebarMenuItem
          title="transactions"
          isOpen={isOpen}
          baseIcon={TransactionsBase}
          activeIcon={TransactionsActive}
          text="Transactions"
          url="/transactions"
          roles={except([roles.COMMS])}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="NQR Registration"
          isOpen={isOpen}
          baseIcon={qr_code_light}
          activeIcon={qr_code_dark}
          text="NQR Registration"
          url="/nqr"
          roles={except([roles.COMMS, roles.KYC_TEAM])}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="terminals"
          isOpen={isOpen}
          baseIcon={TerminalsBase}
          activeIcon={TerminalsActive}
          text="Terminals"
          url="/terminals"
          roles={except([roles.FIELD_SUPERVISOR, roles.COMMS])}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="dispense-errors"
          isOpen={isOpen}
          baseIcon={DispenseErrorsBase}
          activeIcon={DispenseErrorsActive}
          text="Dispense Errors"
          url="/dispense-errors"
          roles={except([roles.POS_TEAM, roles.COMMS, roles.KYC_TEAM])}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="billers"
          isOpen={isOpen}
          baseIcon={BillersBase}
          activeIcon={BillersActive}
          text="Billers"
          url="/billers"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.OPPERATION_HEAD,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="reports"
          isOpen={isOpen}
          baseIcon={ReportsBase}
          activeIcon={ReportsActive}
          text="Reports"
          url="/reports"
          roles={except([roles.COMMS])}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="notification"
          isOpen={isOpen}
          baseIcon={NotificationBase}
          activeIcon={NotificationActive}
          text="Notification"
          url="/notification"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.COMMS,
            roles.OPPERATION_HEAD,
            roles.SUPPORT,
            NOTIFICATION_VIEW.includes(user?.username.toLowerCase()) && roles.REGIONAL_RETENTION_MANAGER,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="campaigns"
          isOpen={isOpen}
          baseIcon={CampaignBase}
          activeIcon={CampaignActive}
          text="Campaigns"
          url="/campaigns"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.OPPERATION_HEAD,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="charges"
          isOpen={isOpen}
          baseIcon={ChargesBase}
          activeIcon={ChargesActive}
          text="Charges"
          url="/charges"
          roles={[
            roles.ADMIN,
            roles.SUPER_ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.OPPERATION_HEAD,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="settings"
          isOpen={isOpen}
          baseIcon={SettingsBase}
          activeIcon={SettingsActive}
          text="Settings"
          url="/settings"
          roles={AllRoles}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="users"
          isOpen={isOpen}
          baseIcon={UsersBase}
          activeIcon={UsersActive}
          text="Users"
          url="/users"
          roles={[roles.SUPER_ADMIN, roles.OPPERATION_HEAD, roles.ADMIN]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Account Utility"
          isOpen={isOpen}
          baseIcon={UsersBase}
          activeIcon={UsersActive}
          text="Account Utility"
          url="/users/fieldstaff-accounts"
          roles={[roles.ADMIN, roles.OPERATIONS_MANAGER, roles.OPERATIONS_ASSISTANT, roles.OPPERATION_HEAD]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="TMS Utility"
          isOpen={isOpen}
          baseIcon={TMSBase}
          activeIcon={TMSActive}
          text="TMS Utility"
          url="/tms"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.ACCOUNTS_TEAM,
            roles.POS_TEAM,
            roles.SUPPORT,
            roles.AGGREGATOR_MANAGER,
            roles.OPPERATION_HEAD,
            roles.REGIONAL_RETENTION_MANAGER,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="TMS V2"
          isOpen={isOpen}
          baseIcon={TMSBase}
          activeIcon={TMSActive}
          text="TMS V2"
          url="/tms-v2"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_MANAGER,
            roles.OPERATIONS_ASSISTANT,
            roles.ACCOUNTS_TEAM,
            roles.POS_TEAM,
            roles.SUPPORT,
            roles.AGGREGATOR_MANAGER,
            roles.GROWTH_MANAGER,
            roles.OPPERATION_HEAD,
            roles.REGIONAL_RETENTION_MANAGER,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Accounts"
          isOpen={isOpen}
          baseIcon={FundsBase}
          activeIcon={FundsActive}
          text="Accounts"
          url="/accounts"
          roles={[
            roles.SUPER_ADMIN,
            roles.ADMIN,
            roles.OPERATIONS_ASSISTANT,
            roles.OPPERATION_HEAD,
            roles.ACCOUNTS_TEAM,
          ]}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Virtual Account"
          isOpen={isOpen}
          baseIcon={FundsBase}
          activeIcon={VirtualAccountActive}
          text="Virtual Account"
          url="/virtual-accounts"
          roles={except([roles.KYC_TEAM])}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="Sales"
          isOpen={isOpen}
          baseIcon={SalesReportBase}
          activeIcon={SalesReportActive}
          text="Sales"
          url="/sales"
          roles={except([roles.KYC_TEAM])}
        />
        <Spacer mt="xl" />
        <SidebarMenuItem
          title="KYC"
          isOpen={isOpen}
          baseIcon={KycBase}
          activeIcon={KycActive}
          text="KYC"
          url="/kyc"
          roles={[
            roles.KYC_TEAM,
            roles.ADMIN,
            roles.SUPER_ADMIN,
            roles.OPPERATION_HEAD,
            roles.REGIONAL_RETENTION_MANAGER,
          ]}
        />
      </Box>
    </SideBarStyles>
  )
}

const MenuItem = styled.div`
  cursor: pointer;
  width: 100%;
  & a {
    position: relative;
    padding: 0 1em;
    width: 100%;
    margin-top: 2em;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
  }

  span.name {
    color: #ffffff66;
    line-height: 1.38;
    display: inline-block;
    margin-left: 2.5em;
    transform: translate3d(-5px, 0, 0);
    max-height: 25px;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }
  &.isOpen {
    span.name {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      height: auto;
    }
    [data-active-icon],
    [data-active-icon] {
      left: 5%;
    }
  }
  [data-active-icon] {
    opacity: 0;
    transform: scale(0);
  }
  [data-active-icon],
  [data-active-icon] {
    transition: all 0.1s linear;
    position: absolute;
    top: -25%;
    left: 10%;
  }
  & .active {
    font-weight: bold;
    color: white;
    span.name {
      color: inherit;
    }

    [data-base-icon] {
      opacity: 0;
      transform: scale(0);
    }
    [data-active-icon] {
      opacity: 1;
      transform: scale(1);
    }
  }
`
const SidebarMenuItem = ({ isOpen, baseIcon, activeIcon, title, url, text, roles }) => (
  <ShowTree forRoles={roles}>
    <MenuItem className={isOpen ? 'isOpen' : ''}>
      <NavLink title={title} to={url}>
        <span className="image">
          <img alt={title} src={baseIcon} data-base-icon />
          <img alt={`${title}-on`} src={activeIcon} data-active-icon />
        </span>
        {isOpen && <span className="name">{text.split(' ').map(capitalize).join(' ')}</span>}
      </NavLink>
    </MenuItem>
  </ShowTree>
)
export default SideBar
