import React, { useReducer, useState } from 'react'
import useQueryParams from 'lib/hooks/useQueryParams'
import { useQuery } from 'react-query'
import toQuery from 'lib/toQuery'
import { pick } from 'ramda'
import {
  fetchAgentKycStatusReport,
  fetchReviewerStatusList,
  fetchOrderList,
  fetchKycUsers,
  fetchAgentKycLevels,
} from 'kyc/Kyc.api.client'
import { fetchAgentTypes, getSearchByList } from 'agents/agents.api.client'
import { useForm } from 'react-hook-form'
import { toNativeDate } from 'lib/formatters'
import useSyncHistoryWith from 'lib/hooks/syncHistoryWith'
import { useToast } from 'toast/ToastContext'

export default function useAgentKycList() {
  const { alert, notify, clearAlert } = useToast()
  const { data: searchOptions } = useQuery(`seachby-list`, getSearchByList)
  const { data: _kycLevelOptions = [] } = useQuery(`fetch-agent-kyc-levels`, fetchAgentKycLevels)
  const kycLevelOptions = React.useMemo(() => {
    return _kycLevelOptions?.map((item) => ({
      ...item,
      name: item.name,
      value: String(item.value),
    }))
  }, [_kycLevelOptions])
  const { data: _agentTypes = [] } = useQuery('agent-types', fetchAgentTypes, { staleTime: Infinity })
  const { data: _statusList = [] } = useQuery('status-list', fetchReviewerStatusList, { staleTime: Infinity })
  const { data: _orderList = [] } = useQuery('order-list', fetchOrderList, { staleTime: Infinity })
  const { data: _users = [] } = useQuery(`fetch-kyc-users`, fetchKycUsers)
  const users = React.useMemo(() => {
    return _users?.map((item) => ({
      ...item,
      name: item.user_name,
      value: item.id,
    }))
  }, [_users])

  const initialState = {
    pageIndex: 1,
    pageSize: 10,
    searchString: '',
    searchBy: '',
    accounttype: '',
    kycLevel: '',
    export: 'false',
    dateRangeFrom: null,
    dateRangeTo: null,
    agentType: '',
    reviwerStatus: '',
    orderByModifiedAt: '',
    currentReviewer: '',
  }

  const queryReducer = (state, action) => {
    switch (action.type) {
      case 'SET_FILTER':
        return {
          ...state,
          [action.payload.field]: action.payload.value,
        }
      case 'SET_PAGE':
        return {
          ...state,
          pageIndex: action.payload,
        }
      case 'RESET_FILTERS':
        return initialState
      case 'UPDATE_DATE_RANGE':
        return {
          ...state,
          dateRangeFrom: action.payload.dateRangeFrom,
          dateRangeTo: action.payload.dateRangeTo,
        }
      default:
        return state
    }
  }

  const [filterState, dispatch] = useReducer(queryReducer, initialState)
  const [query, setQuery] = useState(() => ({
    ...filterState,
  }))

  const setFilters = React.useCallback(
    (payload) => {
      dispatch({ type: 'SET_FILTER', payload })
    },
    [dispatch]
  )

  const handleFilter = React.useCallback(() => {
    setQuery(filterState)
  }, [filterState])

  const handleClear = () => {
    dispatch({ type: 'RESET_FILTERS' })
    setQuery(initialState)
  }

  const onDateChange = ([start, end]) => {
    dispatch({
      type: 'UPDATE_DATE_RANGE',
      payload: { dateRangeFrom: start, dateRangeTo: end },
    })
  }

  const handlePageChange = React.useCallback(
    (page) => {
      dispatch({ type: 'SET-PAGE', payload: page })
      setQuery({ ...filterState, pageIndex: page })
    },
    [filterState, dispatch]
  )

  const handleExport = React.useCallback(() => {
    fetchAgentKycStatusReport({
      ...filterState,
      export: 'true',
    })
      .then(() => notify(`The exported file has been sent to your email address`))
      .catch(() => alert(`There was a problem processing your request`))
  }, [alert, notify, filterState])

  const {
    data: paginatedResponse,
    status,
    isFetching,
  } = useQuery(['fetch-Agent-Kyc-StatusReport-list', query], () => fetchAgentKycStatusReport(query), {
    staleTime: 60 * 60 * 1000,
  })

  return {
    handleFilter,
    onDateChange,
    filterState,
    paginatedResponse,
    status,
    isFetching,
    handleClear,
    searchOptions,
    kycLevelOptions,
    _agentTypes,
    _statusList,
    _orderList,
    users,
    setQuery,
    setFilters,
    handlePageChange,
    handleExport,
    query,
  }
}
