import * as React from 'react'
import Panel from 'design/elements/Panel'
import Box from 'design/elements/Box'
import AgentTable from 'kyc/Tables/AgentKycTable'
import DateRangePicker from 'design/elements/DatePicker'
import { toNativeDate } from 'lib/formatters'
import Button from 'design/elements/Button'
import Spacer from 'design/elements/Spacer'
import Select from 'design/elements/Select'
import Text from 'design/elements/Text'
import { loading } from 'lib/formatters'
import TextField from 'design/elements/TextField'
import { useToggle } from 'lib/hooks/useToggle'
import Modal from 'design/elements/Modal'
import IconBtn from 'design/elements/IconBtn'
import Close from 'design/assets/images/close.svg'
import AssignReviewerModal from 'kyc/screens/AssignReviewerModal'
import useAgentKycList from 'kyc/Hooks/useAgentKycList'

export default function AgentKycList() {
  const {
    handleFilter,
    handlePageChange,
    filterState,
    paginatedResponse,
    status,
    handleExport,
    isFetching,
    onDateChange,
    handleClear,
    searchOptions,
    setFilters,
    kycLevelOptions,
    _agentTypes,
    _statusList,
    _orderList,
    users,
    setQuery,
    query,
  } = useAgentKycList()

  const [reviewerModal, setReviewerModal] = useToggle(false)
  const [agentId, setAgentId] = React.useState('')
  const showModal = (cb) => {
    setReviewerModal()
    cb && cb()
  }
  return (
    <>
      {reviewerModal && (
        <Modal close={setReviewerModal}>
          <Box width="500px" fontSize="sm">
            <Panel px="xl" py="md" display="flex" justifyContent="space-between" alignItems="center">
              <Text>Assign Reviewer </Text>
              <IconBtn onClick={setReviewerModal} color="#8b9ba8">
                <img src={Close} alt="close" />
              </IconBtn>
            </Panel>
            <AssignReviewerModal agentId={agentId} />
          </Box>
        </Modal>
      )}
      <Box onSubmit={handleFilter} disabled={loading(status)}>
        <Panel display="flex" alignItems="center" px="md" py="md" justifyContent="space-between">
          <Box>
            <Text mb="0.2em" fontSize="small" color="highlight">
              Date Range
            </Text>
            <DateRangePicker
              initialDates={[filterState.dateRangeFrom, filterState.dateRangeTo].map((d) => d && toNativeDate(d))}
              onStateChange={onDateChange}
            />
          </Box>
          <Box display="flex" gridGap="1em" alignItems="center">
            <Box>
              <TextField
                name="search"
                onChange={(e) => setFilters({ field: 'searchString', value: e.target.value })}
                label="Search"
                placeholder="Search"
                value={filterState.searchString}
              />
            </Box>
            <Box>
              <Select
                options={searchOptions}
                onChange={(option) => setFilters({ field: 'searchBy', value: option.id })}
                valueField="id"
                label="Search By"
                placeholder="Select Status"
                loading={!searchOptions}
                value={filterState.searchBy}
              />
            </Box>
          </Box>
          <Box display="flex" gridGap="0.5em" alignItems="center" width="35%" mt="20px">
            <Button
              onClick={(e) => {
                e.preventDefault()
                handleExport()
              }}
              type="button"
              loader="Processing Export..."
              loading={isFetching}
              disabled={isFetching}
              variant="dark"
              transparentBorder
              width="30%"
            >
              Export
            </Button>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                handleFilter()
              }}
              loading={loading(status)}
              variant="blue"
              transparentBorder
              width="30%"
            >
              Search
            </Button>
            <Button
              type="button"
              variant="restore"
              onClick={(e) => {
                e.preventDefault()
                handleClear()
              }}
              loading={loading(status)}
              transparentBorder
              Reset
              width="30%"
            >
              Reset
            </Button>
          </Box>
        </Panel>
        <Spacer mt="md" />

        <Panel px="xl" py="md" display="grid" gridTemplateColumns="repeat(4, 1fr)" gridGap="1em" alignItems="flex-end">
          <Select
            label="KYC Level"
            name="kycLevel"
            options={kycLevelOptions}
            loading={!kycLevelOptions.length}
            value={filterState.kycLevel}
            onChange={(option) => {
              setFilters({ field: 'kycLevel', value: option.value })
              const newFilters = { ...query, kycLevel: option.value }
              setQuery(newFilters)
            }}
          />
          <Select
            label="Agent type filter"
            name="agentTypes"
            options={_agentTypes}
            selectedItem={filterState.agentType}
            loading={!_agentTypes.length}
            value={filterState.agentType}
            onChange={(option) => {
              setFilters({ field: 'agentType', value: option.value })
              const newFilters = { ...query, agentType: option.value }
              setQuery(newFilters)
            }}
          />
          <Select
            label="Review status filter"
            name="reviewstatus"
            options={_statusList}
            selectedItem={filterState.reviwerStatus}
            value={filterState.reviwerStatus}
            loading={!_statusList.length}
            onChange={(option) => {
              setFilters({ field: 'reviwerStatus', value: option.value })
              const newFilters = { ...query, reviwerStatus: option.value }
              setQuery(newFilters)
            }}
          />
          <Select
            label="Order by filter"
            name="orderby"
            options={_orderList}
            loading={!_orderList.length}
            value={filterState.orderByModifiedAt}
            onChange={(option) => {
              setFilters({ field: 'orderByModifiedAt', value: option.value })
              const newFilters = { ...query, orderByModifiedAt: option.value }
              setQuery(newFilters)
            }}
          />
          <Select
            label="Current reviewer"
            name="currentreviewer"
            options={users}
            loading={!users.length}
            value={filterState.currentReviewer}
            onChange={(option) => {
                 setFilters({ field: 'currentReviewer', value: option.value })
              const newFilters = { ...query, currentReviewer: option.value }
              setQuery(newFilters)
            }}
          />
        </Panel>
      </Box>
      <AgentTable
        loading={isFetching}
        paginatedResponse={paginatedResponse}
        onPageChange={handlePageChange}
        showModal={showModal}
        setAgentId={setAgentId}
      />
    </>
  )
}
